import React from "react";
import { ResponsiveContainer } from "../helpers/styledComponents";
import styled from "styled-components";
type Mode = "light" | "dark";

const StyledSection = styled.section<{ 
  mode: Mode, 
  style?: object, 
  small?: boolean,
  large?: boolean,
  larger?: boolean,
  largest?: boolean,
  fillHeight?: boolean,
  extraMargin?: boolean,
  backgroundImage?: string
}>`
  background-color: ${props => (props.mode === "light" ? "#fff" : "#0a0a0a")};
  color: ${props => (props.mode === "light" ? "#000" : "#fff")};
  padding: 3rem 1.5rem;
  position: relative;

  ${props => props.small && `padding: 1em 1.5em;`}
  ${props => props.large && `padding: 6em 1.5em;`}
  ${props => props.larger && `@media (min-width: 769px) { padding: 9em 1.5em; }`}
  ${props => props.largest && `@media (min-width: 769px) { padding: 12em 1.5em; }`}
  ${props => props.extraMargin && `
    @media (max-width: 769px) {
      margin-top: 2em; 
      margin-bottom: 2em;      
    }
    
    margin-top: 5em; 
    margin-bottom: 5em;
  `}
  ${props => props.backgroundImage && `
    &::before {
      content: "";
      background-image: url(${props.backgroundImage}); 
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: .35;
    }
  `}

  ${props => props.fillHeight && `
    @media screen and (max-width: 767px) {
      height: calc(100vh - 39.8rem) !important;
    }

    @media screen and (min-width: 768px) {
      height: calc(100vh - 52.75rem) !important;
    }

    @media screen and (min-width: 960px) {
      height: calc(100vh - 47.75rem) !important;
    }

    @media screen and (min-width: 1008px) {
      height: calc(100vh - 46.25rem) !important;
    }

  `}
`;

interface IProps {
  mode: Mode;
  style?: object;
  small?: boolean
  large?: boolean;
  larger?: boolean;
  largest?: boolean;
  fillHeight?: boolean;
  extraMargin?: boolean;
  relaxedPadding?: boolean;
  backgroundImage?: string;
}

const Section: React.FC<IProps> = ({
  children, 
  mode,
  style,
  small,
  large,
  larger,
  largest,
  fillHeight,
  extraMargin,
  relaxedPadding,
  backgroundImage
}) => {
  const responsiveStyle = { padding: "0 5em" };

  if (relaxedPadding) { 
    responsiveStyle.padding = "0";
  }

  return (
    <StyledSection 
      mode={mode}
      style={style}
      small={small}
      large={large}
      larger={larger}
      largest={largest}
      fillHeight={fillHeight}
      extraMargin={extraMargin}
      backgroundImage={backgroundImage}
    >
      <ResponsiveContainer style={responsiveStyle}>
        {children}
      </ResponsiveContainer>
    </StyledSection>
  );
};

export default Section;