import React from "react"
import { Helmet } from "react-helmet"
import { Card, Grid, Image } from "semantic-ui-react";
import Layout from "../components/Layout";
import Section from "../components/Section"
import { HeaderH1, StyledCard } from "../helpers/styledComponents";
import whiteLogo from "../../static/mediaLogos/civicAlliance.png";
import blackLogo from "../../static/mediaLogos/civicAllianceBlack.png";

const headerContent = (
  <HeaderH1 style={{ marginBottom: ".5em", fontSize: "2em" }}>Press Logos</HeaderH1>
);

interface iLogoProps {
  src: string;
};

const LogoCard: React.FC<iLogoProps> = ({ src }) => {
  return (
    <Grid.Column mobile={16} tablet={8} computer={8}>
      <StyledCard raised fluid>
        <Image src={src} wrapped ui={false}/>
        <Card.Content>
          <Card.Description>2714x2714</Card.Description>
          <a href={src} download="civicAlliance.png">Download</a>
        </Card.Content>
      </StyledCard>
    </Grid.Column>)
};


export default () => {
  return (
    <Layout mode="dark" headerContent={headerContent} title="Press Logos | Civic Alliance">
      <Section mode="light" relaxedPadding>
        <Grid>
          <LogoCard src={whiteLogo} />
          <LogoCard src={blackLogo} />
        </Grid>
      </Section>
    </Layout>
  );
}